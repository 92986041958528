:root {
  --bg-color: white;
  --accent-color: black;
  --font-color: black;
}

/* General body styling */
body {
  /* font-family: Helvetica, sans-serif; */
  font-family: monospace;
  color: var(--font-color);
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 5em;
  background-color: var(--bg-color);
  max-width: 43em; /* Default max width for larger screens */
}

/* For smaller screens like phones */
@media (max-width: 500px) {
  body {
      max-width: 85%; /* Allow body to take up more space on smaller devices */
      background-attachment: scroll;
  }
}

header {
  font-size: xx-large;
  margin-bottom: -1em;
  /* padding-bottom: 0%; */
  color: var(--accent-color);
  font-family: Helvetica, sans-serif;
}

a {
  color: var(--accent-color);
}

footer {
  margin-top: 20px;
}
.p-footer {
  margin-top: -2px;
}

table {
  text-align: left;
}

th {
  font-weight: normal;
}

.navbar a {
  font-size: large;
}

.nav-link {
  padding-right: 1em;
}

code {
  display: inline-block; /* Ensures proper spacing when wrapped */
  background-color: #f5f5f5; /* Light gray background for contrast */
  color: #333; /* Dark gray text for readability */
  font-family: "Consolas", "Courier New", monospace; /* Monospace font */
  font-size: 0.9rem; /* Slightly smaller font size */
  padding: 0.2rem 0.4rem; /* Add some padding for spacing */
  border-radius: 4px; /* Rounded corners for a modern look */
  border: 1px solid #ddd; /* Subtle border for clarity */
}

pre {
  background-color: #f5f5f5; /* Matches <code> */
  color: #333;
  font-family: "Consolas", "Courier New", monospace;
  font-size: 0.9rem;
  padding: 1rem; /* Add padding for better readability */
  border-radius: 4px;
  border: 1px solid #ddd;
  overflow-x: auto; /* Allows horizontal scrolling for long lines */
  margin: 0 0 1rem; /* Add margin for spacing */
}

.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.contact-hero {
  text-align: center;
  margin-bottom: 4rem;
}

.contact-hero h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.contact-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 3rem;
  margin-bottom: 4rem;
}

.contact-methods {
  display: grid;
  gap: 2rem;
}

.contact-card {
  background: #f8fafc;
  border-radius: 8px;
  padding: 2rem;
}

.contact-card h3 {
  color: #2563eb;
  margin-bottom: 1rem;
}

.contact-card a {
  color: #2563eb;
  text-decoration: none;
  font-weight: 500;
}

.contact-card a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .contact-grid {
    grid-template-columns: 1fr;
  }
}

.pulse-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.pulse-hero {
  text-align: center;
  padding: 4rem 0;
}

.video-card {
  position: relative;
  overflow: hidden;
}

.video-thumbnail {
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.video-thumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.video-card:hover .video-overlay {
  opacity: 1;
}

.pulse-filters {
  margin: 2rem 0;
}

.gradient-text {
  background: linear-gradient(90deg, #2563eb, #4f46e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.MuiPaper-root {
  background: white;
  transition: transform 0.2s ease-in-out;
}

.MuiCard-root:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

/* Customize scrollbar for webkit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 8px;  /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f5f9;  /* Color of the track */
}

::-webkit-scrollbar-thumb {
  background: #94a3b8;  /* Color of the scroll thumb */
  border-radius: 4px;   /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background: #64748b;  /* Darker color on hover */
}

/* For Firefox */
* {
  scrollbar-width: thin;  /* "auto" or "thin" */
  scrollbar-color: #94a3b8 #f1f5f9;  /* thumb and track color */
}
